import React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export default function Flavors(props) {
  const { flavors, fillings } = props;

  const hasFilings = fillings && Object.entries(fillings).length > 0;

  return (
    <Box>
      <Grid container spacing={2}>
        {!hasFilings && <Grid item sm={3} xs={12}></Grid>}
        <Grid item sm={6} xs={12}>
          <Paper elevation={0} style={{ height: "100%" }}>
            <Box p={2}>
              <Box pt={2}>
                <Typography variant="h4" gutterBottom>
                  Cake Flavors
                </Typography>
                <Box py={2}>
                  <Divider />
                </Box>
              </Box>
              {flavors &&
                Object.entries(flavors)
                  .sort()
                  .map(([key, value]) => {
                    return <Row key={key} value={value} />;
                  })}
            </Box>
          </Paper>
        </Grid>
        {hasFilings && (
          <Grid item sm={6} xs={12}>
            <Paper elevation={0} style={{ height: "100%" }}>
              <Box p={2}>
              <Box pt={2}>
                <Typography variant="h4" gutterBottom>
                  Cake Fillings
                </Typography>
                <Box py={2}>
                  <Divider />
                </Box>
              </Box>
                {fillings &&
                  Object.entries(fillings)
                    .sort()
                    .map(([key, value]) => {
                      return <Row key={key} value={value} />;
                    })}
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

function Row(props) {
  return (
    <Box my={2}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ wordWrap: "anywhere" }}>
              {props.value.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.value.desc}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
