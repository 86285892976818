import React, { useContext } from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import ConfigContext from "../context/ConfigContext";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { getStaticMapUrl } from "../util";
import StoreLocation from "./StoreLocation";
import moment from "moment";
import { STORAGE_BUCKET } from "../constants";

export default function Footer(props) {
  const siteConfig = useContext(ConfigContext);

  const staticMapUrl = getStaticMapUrl(siteConfig.stores);

  return (
    <Box py={10} sx={{ backgroundColor: "#303651", color: "#fff" }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={2} xs={6}>
            <img src={`${STORAGE_BUCKET}/img/b/${siteConfig.id}/logo400.webp`} style={{ width: "100%", filter: "grayscale(1)", borderRadius: "50%", backgroundColor: "#ECECEC" }} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box mb={2} sx={{ color: "#000" }}>
              {siteConfig.stores.map((store, idx) => (
                <StoreLocation key={idx} store={store} index={idx} />
              ))}
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <img style={{ width: "100%" }} src={staticMapUrl} />
            <Box sx={{ textAlign: "right" }}>
              <Typography variant="caption" color="textSecondary">
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box textAlign={"center"}>
          <Box py={2}>
            {siteConfig.ig && (
              <Link color="inherit" style={{ padding: ".5rem" }} target="_blank" href={siteConfig.ig} underline="hover">
                <InstagramIcon />
              </Link>
            )}
            {siteConfig.fb && (
              <Link color="inherit" style={{ padding: ".5rem" }} target="_blank" href={siteConfig.fb} underline="hover">
                <FacebookIcon />
              </Link>
            )}
          </Box>
          <Typography variant="caption">
            &copy; {moment().format("YYYY")} {siteConfig.biz}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
