import React from "react";
import { Box, Typography } from "@mui/material";
import { getStoreClosedDaysOfWeek } from "../../common/util";
import moment from "moment";

const DAY_MAP = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday"
};

export default function AvailabilityCalendar(props) {
  const { storeLocation } = props;

  if (storeLocation.rules?.date?.block) {
    const soldOutDates = storeLocation.rules.date.block.filter((o) => o >= moment().format("YYYY-MM-DD")).sort();
    if (soldOutDates.length > 0) {
      let subtitle = "";

      const storeClosedDays = getStoreClosedDaysOfWeek(storeLocation);
      if (storeClosedDays.length > 0) {
        // transform into string
        subtitle += `We're closed every `;
        subtitle += storeClosedDays.map((o) => DAY_MAP[o]).join(", ");
        subtitle += ` & we're not accepting any orders for the following dates`;
      } else {
        subtitle = "We're not accepting any orders for the following dates";
      }

      return (
        <Box my={4} px={2} py={2} sx={{ border: "1px solid #DEDEDE", borderRadius: "4px" }}>
          <Typography variant="body1" gutterBottom>
            Availability is Limited
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
          <Box mt={2} sx={{ display: "flex", gap: "10px", overflowX: "auto", maxWidth: "85vw" }}>
            {soldOutDates.map((o, idx) => {
              const dt = moment(o);
              return (
                <Box
                  sx={{
                    borderRadius: "4px",
                    padding: "7px",
                    minWidth: "100px",
                    textAlign: "center",
                    backgroundColor: "#fdeded",
                    textDecoration: "line-through"
                  }}
                >
                  <Typography variant="body1">{dt.format("MMM DD")}</Typography>
                  {/* <Typography variant="overline" color="textSecondary" display="block">
              {dt.format("ddd")}
            </Typography> */}
                </Box>
              );
            })}
          </Box>
        </Box>
      );
    }
  }

  return <></>;
}
